* {
    font-family: "Roboto", sans-serif;
  }

  .server{
    margin-top: 6%;
    
  }

  .main-server{
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
  }
  .titulo-server > h1{
    font-size: 260%;
  font-family: "Roboto", sans-serif;
  margin-top: 19%;
  }
  .subtitulo-server >p{
    font-size: 140%;
  font-family: "Roboto", sans-serif;

  }

  