*{ font-family: "Roboto", sans-serif;}
.video-networking{
    object-fit: cover;
    width: 100%;
    height: 75vh;
    position: absolute;
    z-index: -1;
}

.cover-container-networking{
    height: 75vh;
    width: 100%;
    object-fit: contain;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    
}
.card-body{
    text-decoration: 'none';
}
.cover-container-networking > h2 {
    color: white;
    font-size: 300%;
    font-family: "Roboto", sans-serif;
    justify-content: center;
    text-align: center;

}
.cover-container-networking > p {
    color: white;
    font-size: 100%;
    font-family: "Roboto", sans-serif;
    justify-content: center;
    text-align: center;
}

.container-img-portada{
    
    object-fit: contain;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.portada{
    object-fit: cover;
    width: 100%;
    height: 100vh;
    position:absolute;
    z-index: -1;
    
}
.about-portada > h1{
    color: white;
    font-size: 300%;
    font-family: "Roboto", sans-serif;
    justify-content: center;
    text-align: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.9);
    margin-top: 15%;
}

.about-portada > p{
    color: white;
    font-size: 150%;
    font-family: "Roboto", sans-serif;
    justify-content: center;
    text-align: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.3);
}
.figuras-container{
    margin-top: 1%;
    box-shadow: inset 0 0 0 0px rgb(255, 255, 255);
}



.switches-datacenter{
    margin-top: 6%;
    font-family: "Roboto", sans-serif;
}
.switches-sucursales{
    margin-top: 1%;
    font-family: "Roboto", sans-serif;
}
.switches-sistemas-modulares{
    margin-top: 1%;
    font-family: "Roboto", sans-serif;
    margin-bottom: 5%;
}



.Virtual-Edge-Platform{
    margin-top: 6%;
    font-family: "Roboto", sans-serif;
}
.Puerta-de-enlace-perimetral{
    margin-top: 1%;
    font-family: "Roboto", sans-serif;
    margin-bottom: 5%;
}



.systems{
    margin-top: 6%;
    font-family: "Roboto", sans-serif;
}
.operating{
    margin-top: 1%;
    font-family: "Roboto", sans-serif;
    margin-bottom: 5%;
}


.pdf-container{
    margin-top: 6%;
}