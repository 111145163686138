* {
  font-family: "Roboto", sans-serif;
}

.main-seguridad {
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.55);
}

.titulo-seguridad > h1 {
  font-size: 250%;
  font-family: "Roboto", sans-serif;
  text-align:center;
  justify-content:center;
  margin-top: 12.5%;
  
}

.subtitulo-seguridad > h5 {
  font-size: 130%;
  font-family: "Roboto", sans-serif;
}

.capa-seguridad {
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.25);
}

.capa-seguridad-perimetral{
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.28);
}

