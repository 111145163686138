.cover-container {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  box-shadow: inset 0 0 0 1000rem rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

.cover-container > h2 {
  color: white;
  font-size: 200%;
  font-family: "Roboto", sans-serif;
  margin-top: 8%;
}

.cover-container > p {
  color: white;
  font-size: 32rem;
  font-family: "Roboto", sans-serif;
}
.imagen-container {
  float: left;
  width: 45%;
}

.imagenes-video-dell {
  padding: -5%;
  margin-left: 4%;
  width: 30%;
}
.imagenes-video-quantum {
  padding: 2%;
  margin-left: 2%;
}
.imagenes-video-vmware {
 padding: 3%;
}
.imagenes-video-HP {
  padding: 6%;
}
.imagenes-video-microsoft {
  padding: -6%;
  margin-left: 8%;
}
.imagenes-video-kaspersky {
  margin-top: 1.5%;
  padding: 1%;
  margin-left: 2%;
}


.imagenes-video-veam {
  padding: 3%;
  margin-left: -3%;
}
.imagenes-video-cisco {
  padding: 1%;
  margin-top: 4%;
  
}

@media (max-width: 1629px) {
    .cover-container {
      height: 100vh;
      width: 100%;
      object-fit: cover;
      box-shadow: inset 0 0 0 1000rem rgba(0, 0, 0, 0.4);
      display: flex;
      flex-direction:column;
      justify-content: center;
      align-items: center;
    }
    .video {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      display: block;
      z-index: -1;
    }
    .imagen-container {
      float: left;
      width: 60%;
      justify-content: center;
      text-align: center;
      
    }
    .cover-container > h2 {
        color: white;
        font-size: 200%;
        font-family: "Roboto", sans-serif;
        
        justify-content: center;
        text-align: center;
        
      }
  }
  @media (max-width: 1407px) {
    .cover-container {
      height: 100vh;
      width: 100%;
      object-fit: cover;
      box-shadow: inset 0 0 0 1000rem rgba(0, 0, 0, 0.4);
      display: flex;
      flex-direction:column;
      justify-content: center;
      align-items: center;
    }
    .video {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      display: block;
      z-index: -1;
    }
    .imagen-container {
      float: left;
      width: 65%;
      justify-content: center;
      text-align: center;
      
    }
    .cover-container > h2 {
        color: white;
        font-size: 200%;
        font-family: "Roboto", sans-serif;
        justify-content: center;
        text-align: center;
      }
  }

@media (max-width: 1200px) {
    .cover-container {
      height: 100vh;
      width: 100%;
      object-fit: cover;
      box-shadow: inset 0 0 0 1000rem rgba(0, 0, 0, 0.4);
      display: flex;
      flex-direction:column;
      justify-content: center;
      align-items: center;
    }
    .video {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      display: block;
      z-index: -1;
    }
    .imagen-container {
      float: left;
      width: 75%;
      justify-content: center;
      text-align: center;
      
    }
    .cover-container > h2 {
        color: white;
        font-size: 180%;
        font-family: "Roboto", sans-serif;
        justify-content: center;
        text-align: center;
      }
  }

  @media (max-width: 1090px) {
    .cover-container {
      height: 100vh;
      width: 100%;
      object-fit: cover;
      box-shadow: inset 0 0 0 1000rem rgba(0, 0, 0, 0.4);
      display: flex;
      flex-direction:column;
      justify-content: center;
      align-items: center;
    }
    .video {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      display: block;
      z-index: -1;
    }
    .imagen-container {
      float: left;
      width: 75%;
      justify-content: center;
      text-align: center;
    }
    .cover-container > h2 {
        color: white;
        font-size: 170%;
        font-family: "Roboto", sans-serif;
        margin-top: 8%;
        justify-content: center;
        text-align: center;
      }
  }
  
  

@media (max-width: 992px) {
  .cover-container {
    height: 100vh;
    width: 100%;
    object-fit: cover;
    box-shadow: inset 0 0 0 1000rem rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
  }
  .video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    z-index: -1;
  }
  .imagen-container {
    float: left;
    width: 85%;
    text-align: center;
    justify-content: center;
   
  }
  .cover-container > h2 {
    color: white;
    font-size: 160%;
    font-family: "Roboto", sans-serif;
    justify-content: center;
    text-align: center;
    margin-top: 12%;
  }
  
}

@media (max-width: 892px) {
    .cover-container {
      height: 100vh;
      width: 100%;
      object-fit: cover;
      box-shadow: inset 0 0 0 1000rem rgba(0, 0, 0, 0.4);
      display: flex;
      flex-direction:column;
      justify-content: center;
      align-items: center;
    }
    .video {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      display: block;
      z-index: -1;
    }
    .imagen-container {
      float: left;
      width: 65%;
      margin-left: -3%;
     
    }
    .cover-container > h2 {
        color: white;
        font-size: 120%;
        font-family: "Roboto", sans-serif;
        
      }
  }

  @media (max-width: 753px) {
    .cover-container {
      height: 100vh;
      width: 100%;
      object-fit: cover;
      box-shadow: inset 0 0 0 1000rem rgba(0, 0, 0, 0.4);
      display: flex;
      flex-direction:column;
      justify-content: center;
      align-items: center;
    }
    .video {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      display: block;
      z-index: -1;
    }
    .imagen-container {
      float: left;
      width: 75%;
    }
  }
  @media (max-width: 654px) {
    .cover-container {
      height: 100vh;
      width: 100%;
      object-fit: cover;
      box-shadow: inset 0 0 0 1000rem rgba(0, 0, 0, 0.4);
      display: flex;
      flex-direction:column;
      justify-content: center;
      align-items: center;
    }
    .video {
      object-fit: cover;
      width: 150%;
      height: 100%;
      position: absolute;
      display: block;
      z-index: -1;
    }
    .imagen-container {
      float: left;
      width: 85%;
      justify-content: center;
    }
  }

  @media (max-width: 577px) {
    .cover-container {
      height: 100vh;
      width: 150%;
      object-fit: cover;
      box-shadow: inset 0 0 0 1000rem rgba(0, 0, 0, 0.4);
      display: flex;
      flex-direction:column;
      justify-content: center;
      align-items: center;
    }
    .video {
      object-fit: cover;
      width: 170%;
      height: 100%;
      position: absolute;
      display: block;
      z-index: -1;
    }
    .imagen-container {
      float: left;
      width: 95%;
    }
  }
  
  
  
  
