.info-container {
    width: 100%;
    height: 20vh;
    background-color: white;
    color: black;
    
  }
  
  .info {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  @media only screen and (max-width: 750px) {
    .info-container {
      height: 200px;
      width: 100%;
    }
  
    .info > h1 {
      text-align: center;
      font-size: 25px;
    }
  }