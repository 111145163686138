.footer-pro {
  width: 100%;
  height: 20vh;
  background-color: rgba(0, 0, 0, 0.993);
}

.menu-cuerpo{
  margin-left: 19%;

 
}
.redes-sociales{
  
  margin-top: -1%;
  margin-left: 14%;
  
}
.coping{
  margin-top: 1%;
  margin-left: 21%;

}
.nota > h6{
  font-size: 80%;
  margin-top: 1%;
    margin-left: 26%;
    position: absolute;
}
@media (max-width: 1629px) {
  .footer-pro {
    width: 100%;
    height: 45vh;
    background-color: rgba(0, 0, 0, 0.993);
    

  }
  .menu-cuerpo{
    margin-left: 19%;
  
   
  }
  .redes-sociales{
    
   
    margin-top: 1%;
    margin-left: 13%;
    
  }
  .coping{
    margin-top: 4%;
    margin-left: 24%;
   
  
    
  }
  
}

@media (max-width: 1429px) {
  .footer-pro {
    width: 100%;
    height: 26vh;
    background-color: rgba(0, 0, 0, 0.993);

  }
  .menu-cuerpo{
    margin-left: 17%;
    
    
  
   
  }
  .redes-sociales{
    
   
    margin-top: -1%;
    margin-left: 11%;
    
  }
  .coping{
    margin-top: 1%;
    margin-left: 20%;
    
   
  
    
  }
  .nota > h6{
    font-size: 80%;
    margin-top: 1%;
      margin-left: 20%;
      position: absolute;
  }
  
}


@media (max-width: 1100px) {
  .footer-pro {
    width: 100%;
    height: 27vh;
    background-color: rgba(0, 0, 0, 0.993);
  }
  .menu-cuerpo{
    margin-left: 12%;
    
  
   
  }
  .redes-sociales{
    
   
    margin-top: -1%;
    margin-left: 5%;
    
  }
  .coping{
    margin-top: 2%;
    margin-left: 15%;
   
  
    
  }
  .nota > h6{
    font-size: 80%;
    margin-top: 1%;
      margin-left: 5%;
      position: absolute;
  }
}

@media (max-width: 992px) {
  .footer-pro {
    width: 100%;
    height: 26vh;
    background-color: rgba(0, 0, 0, 0.993);
  }
  .menu-cuerpo{
    margin-left: 10%;
  
   
  }
  .redes-sociales{
    
   
    margin-top: -1%;
    margin-left: 5%;
    
  }
  .coping{
    margin-top: 1%;
    margin-left:17%;
   
  
    
  }
}

@media (max-width: 700px) {
  .footer-pro {
    width: 100%;
    height: 50vh;
    background-color: rgba(0, 0, 0, 0.993);
  }
  .menu-cuerpo{
    margin-left: 10%;
  
   
  }
  .redes-sociales{
    
   
    margin-top: 1%;
    margin-left: 5%;
    
  }
  .coping{
    margin-top: 4%;
    margin-left:17%;
   
  
    
  }
}

@media (max-width: 500px) {
  .footer-pro {
    width: 100%;
    
    background-color: rgba(0, 0, 0, 0.993);
  }
  .menu-cuerpo{
    margin-left: 8%;
  
   
  }
  .redes-sociales{
    
   
    margin-top: 2%;
    margin-left: 5%;
    
  }
  .coping{
    margin-top: 8%;
    margin-left:17%;
   
  
    
  }
}

