* {
  font-family: "Roboto", sans-serif;
}
.main-libreria {
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
  
}
.titulo-libreria > h1 {
  font-size: 260%;
  font-family: "Roboto", sans-serif;
  margin-top: 12%;
}

.subtitulo-libreria > h5 {
  font-size: 150%;
  font-family: "Roboto", sans-serif;
  margin-top: 2%;
}

.scalar {
  cursor: pointer;
  font-family: "Roboto", sans-serif;
}

.libreria-container {
  display: flex;
  background-color: rgb(228, 228, 228);
  color: black;
  width: 100%;

  object-fit: cover;
}

.libreria-img {
  margin-top: 3%;
  margin-right: 3%;
}
.libreria-img-vigilancia{
  margin-top:12%;
  margin-right: 5%;
  
 
}
.libreria-desc {
  display: flex;
  flex-direction: column;
  justify-content: right;
  width: 90%;
  padding: 7%;
}
.libreria-desc > h1 {
  margin-bottom: 3%;

  font-size: 225%;
  font-family: "Roboto", sans-serif;
}

.libreria-desc > p {
  margin-bottom: 5%;
  font-size: 125%;
  font-family: "Roboto", sans-serif;
}

.libreria-top3-container {
  display: flex;
  background-color: rgb(255, 255, 255);
  color: black;
  width: 100%;

  object-fit: cover;
  margin-top: 1%;
}
.libreria-top3-desc {
  margin-bottom: 3%;
  font-size: 125%;
  font-family: "Roboto", sans-serif;
}
.libreria-top3-desc > h1 {
  font-size: 225%;
}
.libreria-top3-desc > p {
  margin-top: 2%;
}

.libreria-top4-container {
  display: flex;
  background-color: rgb(255, 255, 255);
  color: black;
  width: 100%;

  object-fit: cover;
 
}
.libreria-top4-desc {
  margin-bottom: 3%;
  font-size: 125%;
  font-family: "Roboto", sans-serif;
}
.libreria-container-componentes {
  display: flex;
  background-color: rgb(228, 228, 228);
  color: black;
  width: 100%;

  object-fit: cover;
}
.libreria-desc-componentes {
  display: flex;
  flex-direction: column;
  justify-content: right;
  width: 90%;
  padding: 5%;
  margin-left: 5%;
}

.libreria-desc-componentes > p {
  margin-bottom: 3%;
  font-size: 125%;
  font-family: "Roboto", sans-serif;
}
.libreria-desc-componentes > h1 {
  margin-bottom: 3%;

  font-size: 225%;
  font-family: "Roboto", sans-serif;
}

.libreria-container-beneficios {
  display: flex;
  background-color: rgba(255, 255, 255, 0.863);
  color: black;
  width: 100%;
  display: grid;
  

  width: 100%;

  object-fit: cover;
}
.libreria-desc-beneficios {
  display: flex;
  margin-left: 5%;
  width: 90%;
  padding: 3%;
}

.libreria-desc-beneficios > p {
  margin-top: 1%;
  text-align: center;
  font-size: 125%;
  font-family: "Roboto", sans-serif;
}
.libreria-desc-beneficios > h1 {
  text-align: center;
}



@media (max-width: 1629px){
  .libreria-container {
    display: flex;
    background-color: rgb(228, 228, 228);
    color: black;
    width: 100%;
  
    object-fit: cover;
  }
  
  .libreria-img {
    margin-top: 5%;
      margin-right: 32%;
      width: 30%;
      height: 50vh;
  }
  .libreria-img-vigilancia{
    margin-top:12%;
    margin-right: 2%;
    
  }
  .libreria-desc {
    display: flex;
    flex-direction: column;
    justify-content: right;
    width: 90%;
    padding: 7.5%;
  }
  .libreria-desc > h1 {
    margin-bottom: 3%;
  
    font-size: 195%;
    font-family: "Roboto", sans-serif;
  }
  
  .libreria-desc > p {
    margin-bottom: 5%;
    font-size: 115%;
    font-family: "Roboto", sans-serif;
  }
  
  .libreria-top3-container {
    display: flex;
    background-color: rgb(255, 255, 255);
    color: black;
    width: 100%;
  
    object-fit: cover;
    margin-top: 1%;
  }
  .libreria-top3-desc {
    margin-bottom: 3%;
    font-size: 125%;
    font-family: "Roboto", sans-serif;
  }
  .libreria-top3-desc > h1 {
    font-size: 225%;
  }
  .libreria-top3-desc > p {
    margin-top: 2%;
  }
  
  .libreria-top4-container {
    display: flex;
    background-color: rgb(255, 255, 255);
    color: black;
    width: 100%;
  
    object-fit: cover;
   
  }
  .libreria-top4-desc {
    margin-bottom: 3%;
    font-size: 125%;
    font-family: "Roboto", sans-serif;
  }
  .libreria-container-componentes {
    display: flex;
    background-color: rgb(228, 228, 228);
    color: black;
    width: 100%;
  
    object-fit: cover;
  }
  .libreria-desc-componentes {
    display: flex;
    
    
    width: 90%;
    padding: 3%;
    margin-left: 5%;
  }
  
  .libreria-desc-componentes > p {
    margin-bottom: 3%;
    font-size: 105%;
    font-family: "Roboto", sans-serif;
  }
  .libreria-desc-componentes > h1 {
    margin-bottom: 3%;
  
    font-size: 185%;
    font-family: "Roboto", sans-serif;
  }
  
  .libreria-container-beneficios {
    display: flex;
    background-color: rgba(255, 255, 255, 0.863);
    color: black;
    width: 100%;
    display: grid;
    
  
    width: 100%;
  
    object-fit: cover;
  }
  .libreria-desc-beneficios {
    display: flex;
    margin-left: 5%;
    width: 90%;
    padding: 3%;
  }
  
  .libreria-desc-beneficios > p {
    margin-top: 1%;
    text-align: center;
    font-size: 125%;
    font-family: "Roboto", sans-serif;
  }
  .libreria-desc-beneficios > h1 {
    text-align: center;
  }


  @media (max-width: 1200px){
    .libreria-container {
      display: flex;
      background-color: rgb(228, 228, 228);
      color: black;
      width: 100%;
    
      object-fit: cover;
    }
    
    .libreria-img {
      margin-top: 8%;
      margin-right: 32%;
      width: 30%;
      height: 50vh;
    }

    .titulo-libreria > h1 {
      font-size: 160%;
      font-family: "Roboto", sans-serif;
      margin-top: 12%;
    }
    
    .subtitulo-libreria > h5 {
      font-size: 110%;
      font-family: "Roboto", sans-serif;
    }
    .libreria-desc {
      display: flex;
      flex-direction: column;
      justify-content: right;
      width: 90%;
      padding: 7%;
      margin-top: 2%;
    }
    .libreria-desc > h1 {
      margin-bottom: 3%;
    
      font-size: 135%;
      font-family: "Roboto", sans-serif;
    }
    
    .libreria-desc > p {
      margin-bottom: 5%;
      font-size: 95%;
      font-family: "Roboto", sans-serif;
    }
    
    .libreria-top3-container {
      display: flex;
      background-color: rgb(255, 255, 255);
      color: black;
      width: 100%;
    
      object-fit: cover;
      margin-top: 1%;
    }
    .libreria-top3-desc {
      margin-bottom: 3%;
      font-size: 105%;
      font-family: "Roboto", sans-serif;
    }
    .libreria-top3-desc > h1 {
      font-size: 205%;
    }
    .libreria-top3-desc > p {
      margin-top: 2%;
    }
    
    .libreria-top4-container {
      display: flex;
      background-color: rgb(255, 255, 255);
      color: black;
      width: 100%;
    
      object-fit: cover;
     
    }
    .libreria-top4-desc {
      margin-bottom: 3%;
      font-size: 105%;
      font-family: "Roboto", sans-serif;
    }
    .libreria-container-componentes {
      display: flex;
      background-color: rgb(228, 228, 228);
      color: black;
      width: 100%;
    
      object-fit: cover;
    }
    .libreria-desc-componentes {
      display: flex;
      flex-direction: column;
      justify-content: right;
      width: 90%;
      padding: 5%;
      margin-left: 5%;
    }
    
    .libreria-desc-componentes > p {
      margin-bottom: 3%;
      font-size: 125%;
      font-family: "Roboto", sans-serif;
    }
    .libreria-desc-componentes > h1 {
      margin-bottom: 3%;
    
      font-size: 225%;
      font-family: "Roboto", sans-serif;
    }
    
    .libreria-container-beneficios {
      display: flex;
      background-color: rgba(255, 255, 255, 0.863);
      color: black;
      width: 100%;
      display: grid;
      
    
      width: 100%;
    
      object-fit: cover;
    }
    .libreria-desc-beneficios {
      display: flex;
      margin-left: 5%;
      width: 90%;
      padding: 3%;
    }
    
    .libreria-desc-beneficios > p {
      margin-top: 1%;
      text-align: center;
      font-size: 125%;
      font-family: "Roboto", sans-serif;
    }
    .libreria-desc-beneficios > h1 {
      text-align: center;
    }
  }
  

  

  @media (max-width: 992px){
    .libreria-container {
      display: flex;
      background-color: rgb(228, 228, 228);
      color: black;
      width: 100%;
    
      object-fit: cover;
    }
    
    .libreria-img {
      margin-top: 3%;
      margin-right: 3%;
    }

    .titulo-libreria > h1 {
      font-size: 130%;
      font-family: "Roboto", sans-serif;
      margin-top: 15%;
    }
    
    .subtitulo-libreria > h5 {
      font-size: 90%;
      font-family: "Roboto", sans-serif;
    }
    .libreria-desc {
      display: flex;
      flex-direction: column;
      justify-content: right;
      width: 90%;
      padding: 7%;
    }
    .libreria-desc > h1 {
      margin-bottom: 3%;
    
      font-size: 225%;
      font-family: "Roboto", sans-serif;
    }
    
    .libreria-desc > p {
      margin-bottom: 5%;
      font-size: 125%;
      font-family: "Roboto", sans-serif;
    }
    
    .libreria-top3-container {
      display: flex;
      background-color: rgb(255, 255, 255);
      color: black;
      width: 100%;
    
      object-fit: cover;
      margin-top: 1%;
    }
    .libreria-top3-desc {
      margin-bottom: 3%;
      font-size: 125%;
      font-family: "Roboto", sans-serif;
    }
    .libreria-top3-desc > h1 {
      font-size: 225%;
    }
    .libreria-top3-desc > p {
      margin-top: 2%;
    }
    
    .libreria-top4-container {
      display: flex;
      background-color: rgb(255, 255, 255);
      color: black;
      width: 100%;
    
      object-fit: cover;
     
    }
    .libreria-top4-desc {
      margin-bottom: 3%;
      font-size: 125%;
      font-family: "Roboto", sans-serif;
    }
    .libreria-container-componentes {
      display: flex;
      background-color: rgb(228, 228, 228);
      color: black;
      width: 100%;
    
      object-fit: cover;
    }
    .libreria-desc-componentes {
      display: flex;
      flex-direction: column;
      justify-content: right;
      width: 90%;
      padding: 5%;
      margin-left: 5%;
    }
    
    .libreria-desc-componentes > p {
      margin-bottom: 3%;
      font-size: 125%;
      font-family: "Roboto", sans-serif;
    }
    .libreria-desc-componentes > h1 {
      margin-bottom: 3%;
    
      font-size: 225%;
      font-family: "Roboto", sans-serif;
    }
    
    .libreria-container-beneficios {
      display: flex;
      background-color: rgba(255, 255, 255, 0.863);
      color: black;
      width: 100%;
      display: grid;
      
    
      width: 100%;
    
      object-fit: cover;
    }
    .libreria-desc-beneficios {
      display: flex;
      margin-left: 5%;
      width: 90%;
      padding: 3%;
    }
    
    .libreria-desc-beneficios > p {
      margin-top: 1%;
      text-align: center;
      font-size: 125%;
      font-family: "Roboto", sans-serif;
    }
    .libreria-desc-beneficios > h1 {
      text-align: center;
    }
  }


  @media (max-width: 860px){
    .libreria-container {
      display: flex;
      background-color: rgb(228, 228, 228);
      color: black;
      width: 100%;
    
      object-fit: cover;
    }
    
    .libreria-img {
      margin-top: 3%;
      margin-right: 3%;
    }

    .titulo-libreria > h1 {
      font-size: 120%;
      font-family: "Roboto", sans-serif;
      margin-top: 15%;
    }
    
    .subtitulo-libreria > h5 {
      font-size: 80%;
      font-family: "Roboto", sans-serif;
    }
    .libreria-desc {
      display: flex;
      flex-direction: column;
      justify-content: right;
      width: 90%;
      padding: 7%;
    }
    .libreria-desc > h1 {
      margin-bottom: 3%;
    
      font-size: 225%;
      font-family: "Roboto", sans-serif;
    }
    
    .libreria-desc > p {
      margin-bottom: 5%;
      font-size: 125%;
      font-family: "Roboto", sans-serif;
    }
    
    .libreria-top3-container {
      display: flex;
      background-color: rgb(255, 255, 255);
      color: black;
      width: 100%;
    
      object-fit: cover;
      margin-top: 1%;
    }
    .libreria-top3-desc {
      margin-bottom: 3%;
      font-size: 125%;
      font-family: "Roboto", sans-serif;
    }
    .libreria-top3-desc > h1 {
      font-size: 225%;
    }
    .libreria-top3-desc > p {
      margin-top: 2%;
    }
    
    .libreria-top4-container {
      display: flex;
      background-color: rgb(255, 255, 255);
      color: black;
      width: 100%;
    
      object-fit: cover;
     
    }
    .libreria-top4-desc {
      margin-bottom: 3%;
      font-size: 125%;
      font-family: "Roboto", sans-serif;
    }
    .libreria-container-componentes {
      display: flex;
      background-color: rgb(228, 228, 228);
      color: black;
      width: 100%;
    
      object-fit: cover;
    }
    .libreria-desc-componentes {
      display: flex;
      flex-direction: column;
      justify-content: right;
      width: 90%;
      padding: 5%;
      margin-left: 5%;
    }
    
    .libreria-desc-componentes > p {
      margin-bottom: 3%;
      font-size: 125%;
      font-family: "Roboto", sans-serif;
    }
    .libreria-desc-componentes > h1 {
      margin-bottom: 3%;
    
      font-size: 225%;
      font-family: "Roboto", sans-serif;
    }
    
    .libreria-container-beneficios {
      display: flex;
      background-color: rgba(255, 255, 255, 0.863);
      color: black;
      width: 100%;
      display: grid;
      
    
      width: 100%;
    
      object-fit: cover;
    }
    .libreria-desc-beneficios {
      display: flex;
      margin-left: 5%;
      width: 90%;
      padding: 3%;
    }
    
    .libreria-desc-beneficios > p {
      margin-top: 1%;
      text-align: center;
      font-size: 125%;
      font-family: "Roboto", sans-serif;
    }
    .libreria-desc-beneficios > h1 {
      text-align: center;
    }
  }

  @media (max-width: 780px){
    .libreria-container {
      display: flex;
      background-color: rgb(228, 228, 228);
      color: black;
      width: 100%;
    
      object-fit: cover;
    }
    
    .libreria-img {
      margin-top: 3%;
      margin-right: 3%;
    }

    .titulo-libreria > h1 {
      font-size: 110%;
      font-family: "Roboto", sans-serif;
      margin-top: 15%;
    }
    
    .subtitulo-libreria > h5 {
      font-size: 70%;
      font-family: "Roboto", sans-serif;
      margin-bottom: 12%;
    }
    .libreria-desc {
      display: flex;
      flex-direction: column;
      justify-content: right;
      width: 90%;
      padding: 7%;
    }
    .libreria-desc > h1 {
      margin-bottom: 3%;
    
      font-size: 225%;
      font-family: "Roboto", sans-serif;
    }
    
    .libreria-desc > p {
      margin-bottom: 5%;
      font-size: 125%;
      font-family: "Roboto", sans-serif;
    }
    
    .libreria-top3-container {
      display: flex;
      background-color: rgb(255, 255, 255);
      color: black;
      width: 100%;
    
      object-fit: cover;
      margin-top: 1%;
    }
    .libreria-top3-desc {
      margin-bottom: 3%;
      font-size: 125%;
      font-family: "Roboto", sans-serif;
    }
    .libreria-top3-desc > h1 {
      font-size: 225%;
    }
    .libreria-top3-desc > p {
      margin-top: 2%;
    }
    
    .libreria-top4-container {
      display: flex;
      background-color: rgb(255, 255, 255);
      color: black;
      width: 100%;
    
      object-fit: cover;
     
    }
    .libreria-top4-desc {
      margin-bottom: 3%;
      font-size: 125%;
      font-family: "Roboto", sans-serif;
    }
    .libreria-container-componentes {
      display: flex;
      background-color: rgb(228, 228, 228);
      color: black;
      width: 100%;
    
      object-fit: cover;
    }
    .libreria-desc-componentes {
      display: flex;
      flex-direction: column;
      justify-content: right;
      width: 90%;
      padding: 5%;
      margin-left: 5%;
    }
    
    .libreria-desc-componentes > p {
      margin-bottom: 3%;
      font-size: 125%;
      font-family: "Roboto", sans-serif;
    }
    .libreria-desc-componentes > h1 {
      margin-bottom: 3%;
    
      font-size: 225%;
      font-family: "Roboto", sans-serif;
    }
    
    .libreria-container-beneficios {
      display: flex;
      background-color: rgba(255, 255, 255, 0.863);
      color: black;
      width: 100%;
      display: grid;
      
    
      width: 100%;
    
      object-fit: cover;
    }
    .libreria-desc-beneficios {
      display: flex;
      margin-left: 5%;
      width: 90%;
      padding: 3%;
    }
    
    .libreria-desc-beneficios > p {
      margin-top: 1%;
      text-align: center;
      font-size: 125%;
      font-family: "Roboto", sans-serif;
    }
    .libreria-desc-beneficios > h1 {
      text-align: center;
    }
  }

  @media (max-width: 640px){
    .libreria-container {
      display: flex;
      background-color: rgb(228, 228, 228);
      color: black;
      width: 100%;
      
    
      object-fit: cover;
    }
    
    
    .libreria-img {
      margin-top: 3%;
      margin-right: 3%;
      
    }

    .titulo-libreria > h1 {
      font-size: 10%;
      font-family: "Roboto", sans-serif;
      margin-top: -10%;
    }
    
    .subtitulo-libreria > h5 {
      font-size: 7%;
      font-family: "Roboto", sans-serif;
      margin-bottom: 12%;
    }
    .libreria-desc {
      display: flex;
      flex-direction: column;
      justify-content: right;
      width: 90%;
      padding: 7%;
    }
    .libreria-desc > h1 {
      margin-bottom: 3%;
    
      font-size: 225%;
      font-family: "Roboto", sans-serif;
    }
    
    .libreria-desc > p {
      margin-bottom: 5%;
      font-size: 125%;
      font-family: "Roboto", sans-serif;
    }
    
    .libreria-top3-container {
      display: flex;
      background-color: rgb(255, 255, 255);
      color: black;
      width: 100%;
    
      object-fit: cover;
      margin-top: 1%;
    }
    .libreria-top3-desc {
      margin-bottom: 3%;
      font-size: 125%;
      font-family: "Roboto", sans-serif;
    }
    .libreria-top3-desc > h1 {
      font-size: 225%;
    }
    .libreria-top3-desc > p {
      margin-top: 2%;
    }
    
    .libreria-top4-container {
      display: flex;
      background-color: rgb(255, 255, 255);
      color: black;
      width: 100%;
    
      object-fit: cover;
     
    }
    .libreria-top4-desc {
      margin-bottom: 3%;
      font-size: 125%;
      font-family: "Roboto", sans-serif;
    }
    .libreria-container-componentes {
      display: flex;
      background-color: rgb(228, 228, 228);
      color: black;
      width: 100%;
    
      object-fit: cover;
    }
    .libreria-desc-componentes {
      display: flex;
      flex-direction: column;
      justify-content: right;
      width: 90%;
      padding: 5%;
      margin-left: 5%;
    }
    
    .libreria-desc-componentes > p {
      margin-bottom: 3%;
      font-size: 125%;
      font-family: "Roboto", sans-serif;
    }
    .libreria-desc-componentes > h1 {
      margin-bottom: 3%;
    
      font-size: 225%;
      font-family: "Roboto", sans-serif;
    }
    
    .libreria-container-beneficios {
      display: flex;
      background-color: rgba(255, 255, 255, 0.863);
      color: black;
      width: 100%;
      display: grid;
      
    
      width: 100%;
    
      object-fit: cover;
    }
    .libreria-desc-beneficios {
      display: flex;
      margin-left: 5%;
      width: 90%;
      padding: 3%;
    }
    
    .libreria-desc-beneficios > p {
      margin-top: 1%;
      text-align: center;
      font-size: 125%;
      font-family: "Roboto", sans-serif;
    }
    .libreria-desc-beneficios > h1 {
      text-align: center;
    }
  }

  @media (max-width: 780px){
    .libreria-container {
      display: flex;
      background-color: rgb(228, 228, 228);
      color: black;
      width: 100%;
    
      object-fit: cover;
    }
    
    .libreria-img {
      margin-top: 3%;
      margin-right: 3%;
    }

    .titulo-libreria > h1 {
      font-size: 110%;
      font-family: "Roboto", sans-serif;
      margin-top: 15%;
    }
    
    .subtitulo-libreria > h5 {
      font-size: 70%;
      font-family: "Roboto", sans-serif;
      margin-bottom: 12%;
    }
    .libreria-desc {
      display: flex;
      flex-direction: column;
      justify-content: right;
      width: 90%;
      padding: 7%;
    }
    .libreria-desc > h1 {
      margin-bottom: 3%;
    
      font-size: 225%;
      font-family: "Roboto", sans-serif;
    }
    
    .libreria-desc > p {
      margin-bottom: 5%;
      font-size: 125%;
      font-family: "Roboto", sans-serif;
    }
    
    .libreria-top3-container {
      display: flex;
      background-color: rgb(255, 255, 255);
      color: black;
      width: 100%;
    
      object-fit: cover;
      margin-top: 1%;
    }
    .libreria-top3-desc {
      margin-bottom: 3%;
      font-size: 125%;
      font-family: "Roboto", sans-serif;
    }
    .libreria-top3-desc > h1 {
      font-size: 225%;
    }
    .libreria-top3-desc > p {
      margin-top: 2%;
    }
    
    .libreria-top4-container {
      display: flex;
      background-color: rgb(255, 255, 255);
      color: black;
      width: 100%;
    
      object-fit: cover;
     
    }
    .libreria-top4-desc {
      margin-bottom: 3%;
      font-size: 125%;
      font-family: "Roboto", sans-serif;
    }
    .libreria-container-componentes {
      display: flex;
      background-color: rgb(228, 228, 228);
      color: black;
      width: 100%;
    
      object-fit: cover;
    }
    .libreria-desc-componentes {
      display: flex;
      flex-direction: column;
      justify-content: right;
      width: 90%;
      padding: 5%;
      margin-left: 5%;
    }
    
    .libreria-desc-componentes > p {
      margin-bottom: 3%;
      font-size: 125%;
      font-family: "Roboto", sans-serif;
    }
    .libreria-desc-componentes > h1 {
      margin-bottom: 3%;
    
      font-size: 225%;
      font-family: "Roboto", sans-serif;
    }
    
    .libreria-container-beneficios {
      display: flex;
      background-color: rgba(255, 255, 255, 0.863);
      color: black;
      width: 100%;
      display: grid;
      
    
      width: 100%;
    
      object-fit: cover;
    }
    .libreria-desc-beneficios {
      display: flex;
      margin-left: 5%;
      width: 90%;
      padding: 3%;
    }
    
    .libreria-desc-beneficios > p {
      margin-top: 1%;
      text-align: center;
      font-size: 125%;
      font-family: "Roboto", sans-serif;
    }
    .libreria-desc-beneficios > h1 {
      text-align: center;
    }
  }
}