* {
    font-family: "Roboto", sans-serif;
  }
  .video-about{
    object-fit: cover;
    width: 100%;
    height: 90vh;
    position: absolute;
    z-index: -1;
}

.cover-container-about{
    height: 90vh;
    width: 100%;
    object-fit: contain;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    
}
.card-body{
    text-decoration: 'none';
}
.cover-container-about > h2 {
    color: white;
    font-size: 350%;
    font-family: "Roboto", sans-serif;
    justify-content: center;
    text-align: center;

}
.cover-container-about > p {
    color: white;
    font-size: 130%;
    font-family: "Roboto", sans-serif;
    justify-content: center;
    text-align: center;
}



@media (max-width: 1600px) {
  .capa-filosofia{
    height: 100%;

  }
  .capa-quehacemos{
    height: 100%;
  
   
  }
  
  
}

  