* {
    font-family: "Roboto", sans-serif;
  }


  .main-software {
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.45);
  }
  
  .titulo-software > h1 {
    font-size: 250%;
    font-family: "Roboto", sans-serif;
    text-align:center;
    justify-content:center;
    margin-top: 20.5%;
    
  }
  
  .subtitulo-software > h5 {
    font-size: 130%;
    font-family: "Roboto", sans-serif;
  }