.navbar{
    position: absolute;
    object-fit:cover;
    background-color:rgb(0, 0, 0);
    width: 100%;
    height: 10%;
    
    font-size: 90%;
    z-index:-1;
    font-family: "Roboto", sans-serif;
    
    
    justify-content: space-between;
}
.scrolling{
    background-color: black;
    transition: all 0.5s ease;
    height: 100%;
}
.navbar-logo{
    height: 80px;
    line-height: 80px;
    cursor: pointer;
}

