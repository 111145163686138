* {
  font-family: "Roboto", sans-serif;
}

.CiscoBorder {
  margin-top: 6%;
  height: 10vh;
}

.DellBorder {
  margin-top: 1%;
}

.funcionalidad-nube-contenedor {
  display: flex;
  background-color: rgb(0, 0, 0);
  color: rgb(243, 243, 243);
  width: 100%;

  object-fit: cover;

  margin-right: 10%;
}
.funcionalidad-nube-desc {
  margin-left: 6%;

  width: 100%;
  padding: 5%;
}
.funcionalidad-nube-desc > h1 {
  
 
  font-size: 225%;
  font-family: "Roboto", sans-serif;
}
.funcionalidad-nube-desc > p {
  margin-bottom: 3%;
  font-size: 125%;
  font-family: "Roboto", sans-serif;
}
