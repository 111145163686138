*{ font-family: "Roboto", sans-serif;}

.titulo > h1{
  color: white;
  font-size: 260%;
  
  
  justify-content: center;
  align-items: center;

 
}
.portada-main{
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.437);
}




.Contenedor-imagenes {
  width:100%;
  
  display: grid;
  ;
  height: 200vh;
  grid-template-columns: repeat(auto-fill, 5%);
  gap: 7%;
  padding: 5%;
  margin-top: -1%;
  background-image:linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.925),
    rgba(253, 252, 252, 0.8)
  ), url("../../../../public/img/fondo-oficinita5.jpg");
  
  
}
.figura-azul {
  position: relative;
  height: 230px;
  object-fit: cover;
  cursor: pointer;
  width: 360px;
  overflow: hidden;
  border-radius: 50px;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.986);
}

.capa-imagenes-azul {
  width: 100%;
  height: 100%;
  transition: all 400ms ease-out;
  will-change: transform;
}

.capa-azul {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(2, 109, 131, 0.75);
  transition: all 400ms ease-out;
  opacity: 0;
  visibility: hidden;
  text-align: center;
}
.figura-azul:hover > .capa-azul {
  opacity: 1;
  visibility: visible;
}
.figura-azul:hover > .capa-azul h3 {
  margin-top: 25%;
 
}
.capa-imagenes-azul:hover {
  transform: scale(1.1);
}
.capa-azul > h3 {
  color: #fff;
  font-weight: 400;
  margin-bottom: 120px;
  transition: all 500ms ease-out;
  margin-top: 30px;
  font-family: "Roboto", sans-serif;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.986);

}
.capa-azul > p {
  color: #fff;
  font-size: 90%;
  line-height: 1.5;
  width: 100%;
  max-width: 220px;
  margin: auto;
  font-family: "Roboto", sans-serif;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.986);
}




.figura-naranja {
    position: relative;
    height: 230px;
    object-fit: cover;
    cursor: pointer;
    width: 360px;
    overflow: hidden;
    border-radius: 50px;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.986);
  }
  
  .capa-imagenes-naranja {
    width: 100%;
    height: 100%;
    transition: all 400ms ease-out;
    will-change: transform;
  }
  
  .capa-naranja {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(235, 138, 27, 0.6);
    transition: all 400ms ease-out;
    opacity: 0;
    visibility: hidden;
    text-align: center;
  }
  .figura-naranja:hover > .capa-naranja {
    opacity: 1;
    visibility: visible;
  }
  .figura-naranja:hover > .capa-naranja h3 {
    margin-top: 25%;
    margin-bottom: 15px;
  }
  .capa-imagenes-naranja:hover {
    transform: scale(1.1);
  }
  .capa-naranja > h3 {
    color: white;
    font-weight: 400;
    margin-bottom: 120px;
    transition: all 500ms ease-out;
    margin-top: 30px;
    font-family: "Roboto", sans-serif;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.986);
  }
  .capa-naranja > p {
    color: white;
    font-weight: 400;
    font-size: 90%;
    line-height: 1.5;
    width: 100%;
    max-width: 220px;
    margin: auto;
    font-family: "Roboto", sans-serif;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.986);
  }



  .figura-verde {
    position: relative;
    height: 230px;
    object-fit: cover;
    cursor: pointer;
    width: 360px;
    overflow: hidden;
    border-radius: 50px;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.986);
  }
  
  .capa-imagenes-verde {
    width: 100%;
    height: 100%;
    transition: all 400ms ease-out;
    will-change: transform;
  }
  
  .capa-verde {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 170, 77, 0.55);
    transition: all 400ms ease-out;
    opacity: 0;
    visibility: hidden;
    text-align: center;
    
  }
  .figura-verde:hover > .capa-verde {
    opacity: 1;
    visibility: visible;
  }
  .figura-verde:hover > .capa-verde h3 {
    margin-top: 25%;
    margin-bottom: 15px;
  }
  .capa-imagenes-verde:hover {
    transform: scale(1.1);
  }
  .capa-verde > h3 {
    color: #fff;
    font-weight: 400;
    margin-bottom: 120px;
    transition: all 500ms ease-out;
    margin-top: 30px;
    font-family: "Roboto", sans-serif;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.986);
  }
  .capa-verde > p {
    color: #fff;
    font-size: 90%;
    line-height: 1.5;
    width: 100%;
    max-width: 220px;
    margin: auto;
    font-family: "Roboto", sans-serif;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.986);
    
  }



  .figura-gris {
    position: relative;
    height: 230px;
    object-fit: cover;
    cursor: pointer;
    width: 360px;
    overflow: hidden;
    border-radius: 50px;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.996);
  }
  
  .capa-imagenes-gris {
    width: 100%;
    height: 100%;
    transition: all 400ms ease-out;
    will-change: transform;
  }
  
  .capa-gris {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(8, 8, 8, 0.63);
    transition: all 400ms ease-out;
    opacity: 0;
    visibility: hidden;
    text-align: center;
  }
  .figura-gris:hover > .capa-gris {
    opacity: 1;
    visibility: visible;
  }
  .figura-gris:hover > .capa-gris h3 {
    margin-top: 25%;
    margin-bottom: 15px;
  }
  .capa-imagenes-gris:hover {
    transform: scale(1.1);
  }
  .capa-gris > h3 {
    color: #fff;
    font-weight: 400;
    margin-bottom: 120px;
    transition: all 500ms ease-out;
    margin-top: 30px;
    font-family: "Roboto", sans-serif;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.986);
  }
  .capa-gris > p {
    color: #fff;
    font-size: 90%;
    line-height: 1.5;
    width: 100%;
    max-width: 220px;
    margin: auto;
    font-family: "Roboto", sans-serif;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.986);
  }
  