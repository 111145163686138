*{ font-family: "Roboto", sans-serif;}
.video-almacenamiento{
    object-fit: cover;
    width: 100%;
    height: 90vh;
    position: absolute;
    z-index: -1;
}

.cover-container-almacenamiento{
    height: 90vh;
    width: 100%;
    object-fit: contain;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    
}
.card-body{
    text-decoration: 'none';
}
.cover-container-almacenamiento > h2 {
    color: white;
    font-size: 350%;
    font-family: "Roboto", sans-serif;
    justify-content: center;
    text-align: center;

}
.cover-container-almacenamiento > p {
    color: white;
    font-size: 130%;
    font-family: "Roboto", sans-serif;
    justify-content: center;
    text-align: center;
}
.datosprimarios-container-PowerMax{
    display:flex;
    background-color: rgb(0, 0, 0);
    color: white;

    object-fit:cover;
    margin-top: 7%;
    
    
    margin-left: 10%;
    margin-right: 10%;
    
    

}

.about-desc-PowerMax{
    display: flex;
    flex-direction: column;
    justify-content: right;
    width:70%;
    padding: 7%;
}
.about-desc-PowerMax > h1{
    margin-bottom: 3%;
    font-size: 225%;
    font-family: "Roboto", sans-serif;
}
.about-desc-PowerMax > p{
    margin-bottom: 3%;
    font-size: 125%;
    font-family: "Roboto", sans-serif;
}

.datosprimarios-container-PowerStore{
    display:flex;
    background-color: rgb(212, 212, 212);
    color: black;

    object-fit:cover;
    margin-top: 5%;
    
    
    margin-left: 10%;
    margin-right: 10%;
}
.about-desc-PowerStore{
    display: flex;
    flex-direction: column;
    justify-content: right;
    width:70%;
    padding: 7%;
}
.about-desc-PowerStore > h1{
    margin-bottom: 3%;
    font-size: 225%;
    font-family: "Roboto", sans-serif;

}
.about-desc-PowerStore > p{
    margin-bottom: 3%;
    font-size: 125%;
    font-family: "Roboto", sans-serif;
    
}




.datosprimarios-container-Unity{
    display:flex;
    background-color: rgb(0, 0, 0);
    color: white;

    object-fit:cover;
    margin-top: 5%;
    
    
    margin-left: 10%;
    margin-right: 10%;
}
.about-desc-Unity{
    display: flex;
    flex-direction: column;
    justify-content: right;
    width:70%;
    padding: 7%;
}
.about-desc-Unity > h1{
    margin-bottom: 3%;
    font-size: 225%;
    font-family: "Roboto", sans-serif;

}
.about-desc-Unity > p{
    margin-bottom: 3%;
    font-size: 125%;
    font-family: "Roboto", sans-serif;
}



.datosprimarios-container-PowerVault{
    display:flex;
    background-color: rgb(212, 212, 212);
    color: black;

    object-fit:cover;
    margin-top: 5%;
    
    
    margin-left: 10%;
    margin-right: 10%;
}
.about-desc-PowerVault{
    display: flex;
    flex-direction: column;
    justify-content: right;
    width:70%;
    padding: 7%;
}
.about-desc-PowerVault > h1{
    margin-bottom: 3%;
    font-size: 225%;
    font-family: "Roboto", sans-serif;

}
.about-desc-PowerVault > p{
    margin-bottom: 3%;
    font-size: 125%;
    font-family: "Roboto", sans-serif;
}



.datosprimarios-container-VMAX{
    display:flex;
    background-color: rgb(0, 0, 0);
    color: white;

    object-fit:cover;
    margin-top: 5%;
    
    
    margin-left: 10%;
    margin-right: 10%;
}
.about-desc-VMAX{
    display: flex;
    flex-direction: column;
    justify-content: right;
    width:70%;
    padding: 7%;
}
.about-desc-VMAX > h1{
    margin-bottom: 3%;
    font-size: 225%;
    font-family: "Roboto", sans-serif;

}
.about-desc-VMAX > p{
    margin-bottom: 3%;
    font-size: 125%;
    font-family: "Roboto", sans-serif;
}


