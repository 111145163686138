.container-img-portada-cisco{
    
    object-fit: contain;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.portada-cisco{
    object-fit: cover;
    width: 100%;
    height: 100vh;
    position:absolute;
    z-index: -1;
    
}
.about-portada-cisco > h1{
    color: white;
    font-size: 300%;
    font-family: "Roboto", sans-serif;
    justify-content: center;
    text-align: center;
    box-shadow: inset  0 0 0 1000px rgba(0, 0, 0, 0.6);
    margin-top: 15%;
}
.Menu-container{
    margin-top: 1%;
}