.card-group:hover{
    box-shadow: 5px 10px 20px 1px rgba(28, 77, 122, 0.966) !important;
    transition: all 0.5s linear;
    
}

.card-text{
    
    font-size: 1rem;
}

.card-img-top:hover{
    transform: scale(1.3);
}
.card-img-top{
    transition: all 0.3s ease-in-out;

}

.overflow{
    overflow: hidden;
}

.card-body{
    text-decoration: 'none';
}




