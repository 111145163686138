.about-container {
  background-color: black;
  color: white;
  height: 90vh;
}

.about-desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  margin-left: 10%;
  padding: 0 100px 0 100px;
  margin-top: 1%;
}

.about-desc > h1 {
  margin-bottom: 20px;
  font-size: 290%;
}

.about-desc > p {
  line-height: 1.5;
  font-size: 150%;
}

.about-img {
  width: 100%;

  align-self: center;
}

@media (max-width: 1429px) {
  .about-container {
    background-color: black;
    color: white;
    height: 95vh;
  }
  .about-desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    margin-left: 10.5%;
    padding: 0 100px 0 100px;
    margin-top: 1%;
  }

  .about-desc > h1 {
    margin-bottom: 30px;
    font-size: 220%;
  }

  .about-desc > p {
    line-height: 1.5;
    font-size: 110%;
  }

  .about-img {
    width: 35%;
    margin-left: 33%;
    display: flex;
    align-self: center;
  }

  @media (max-width: 1200px) {
    .about-container {
      background-color: black;
      color: white;
      height: 75vh;
    }
    .about-desc {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 60%;
      margin-left: 22%;
      padding: 0 100px 0 100px;
      margin-top: 1%;
    }

    .about-desc > h1 {
      margin-bottom: 5%;
      font-size: 150%;
    }

    .about-desc > p {
      line-height: 1.5;
      font-size: 80%;
    }

    .about-img {
      width: 30%;
      
      margin-left: 37%;
      display: flex;
      align-self: center;
    }
  }

  @media (max-width: 992px) {
    .about-container {
      
      background-color: black;
      color: white;
      height: 72vh;
      
    }
    .about-desc {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 60%;
      margin-left: 22%;
      padding: 0 100px 0 100px;
      margin-top: 1%;
    }

    .about-desc > h1 {
      margin-bottom: 7%;
      font-size: 135%;
    }

    .about-desc > p {
      line-height: 1.5;
      font-size: 68%;
    }

    .about-img {
      width: 30%;
      margin-left: 37%;
      display: flex;
      align-self: center;
    }
  }
}
